/** ICON FONT
--------------------------------------------------------- */

/** DEFAULTS
--------------------------------------------------------- */

/** BREAKPOINTS
--------------------------------------------------------- */

/** SIZES
--------------------------------------------------------- */

/** COLORS
--------------------------------------------------------- */

/** DEFAULT ICON STYLES
--------------------------------------------------------- */

@import url(~@fortawesome/fontawesome-free/css/all.min.css);

@import url(~@fortawesome/fontawesome-free/css/v4-shims.min.css);

@import url(~animate.css/animate.min.css);

@import url(~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css);

.icn {
  display: inline-block;
  vertical-align: middle;
  width: 32px;
  height: 32px;
  stroke: #b1b7bd;
}

.icn-bubble {
  display: inline-block;
  width: 67px;
  height: 67px;
  line-height: 67px;
  text-align: center;
  border-radius: 50%;
  background-color: #f3f3f3;
  transition: background .4s ease-in-out;
}

@media (min-width: 768px) {
  .icn-bubble {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }

  .icn-bubble .icn {
    width: 64px;
    height: 64px;
  }
}

*:hover > .icn-bubble {
  background-color: #0090d0;
}

*:hover > .icn-bubble .icn {
  stroke: #ffffff;
}

/** SIZES
--------------------------------------------------------- */

.icn-size-xs {
  width: 11px !important;
  height: 11px !important;
}

.icn-bubble-xs {
  width: 22px !important;
  height: 22px !important;
  line-height: 22px !important;
}

.icn-bubble-xs .icn {
  width: 11px !important;
  height: 11px !important;
}

.icn-size-sm {
  width: 16px !important;
  height: 16px !important;
}

.icn-bubble-sm {
  width: 32px !important;
  height: 32px !important;
  line-height: 32px !important;
}

.icn-bubble-sm .icn {
  width: 16px !important;
  height: 16px !important;
}

.icn-size-md {
  width: 32px !important;
  height: 32px !important;
}

.icn-bubble-md {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
}

.icn-bubble-md .icn {
  width: 32px !important;
  height: 32px !important;
}

.icn-size-lg {
  width: 48px !important;
  height: 48px !important;
}

.icn-bubble-lg {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px !important;
}

.icn-bubble-lg .icn {
  width: 48px !important;
  height: 48px !important;
}

.icn-size-xl {
  width: 64px !important;
  height: 64px !important;
}

.icn-bubble-xl {
  width: 128px !important;
  height: 128px !important;
  line-height: 128px !important;
}

.icn-bubble-xl .icn {
  width: 64px !important;
  height: 64px !important;
}

/** COLORS
--------------------------------------------------------- */

.icn-color-white {
  stroke: #ffffff !important;
}

.icn-color-black {
  stroke: #000000 !important;
}

.icn-color-primary {
  stroke: #0090d0 !important;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 0) !important;
  -ms-transform: translate(0, 0) !important;
  -o-transform: translate(0, 0) !important;
  transform: translate(0, 0) !important;
}

.fade.show {
  opacity: 1;
}

