/** ICON FONT
--------------------------------------------------------- */
@import "icon-font/o2/variables";
@import "icon-font/icon-font";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~@fortawesome/fontawesome-free/css/v4-shims.min.css";
// animate.css
@import "~animate.css/animate.min.css";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";

.modal.show .modal-dialog {
    -webkit-transform: translate(0, 0) !important;
    -ms-transform: translate(0, 0) !important;
    -o-transform: translate(0, 0) !important;
    transform: translate(0, 0) !important;
}
.fade {
    &.show {
        opacity: 1;
    }
}
